import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleCard from '../shared/CollapsibleCard';

const CbpSecuritySealItem = ({ applicant, setCurrentView }) => {
  return (
    <li className="px-0 dash-li">
      <CollapsibleCard collapseId="CbpSecSeal" header={<button onClick={() => setCurrentView('securitySealForm')}><i className="far fa-hand-point-up"></i> Application for CBP Security Seal</button>}>
        {applicant.cbp_security_seal && (
          <div className="card-body p-0">
            <table className="table">
              <tbody>
                <tr>
                  <th className="font-weight-bold border-bottom-0">Date</th>
                  <th className="font-weight-bold border-bottom-0">Zones Requested</th>
                  <th className="font-weight-bold border-bottom-0">Specific Description of Duties</th>
                </tr>
                <tr>
                  <td className="font-weight-normal">
                    {new Date(applicant.cbp_security_seal.created_at).toLocaleDateString()}
                  </td>
                  <td className="font-weight-normal">
                    {applicant.cbp_security_seal.zone_requested
                      ?.split(", ")
                      .map(zone => zone.charAt(0).toUpperCase() + zone.slice(1))
                      .join(", ")}
                  </td>
                  <td className="font-weight-normal">{applicant.cbp_security_seal.spec_desc}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </CollapsibleCard>
    </li>
  );
};

CbpSecuritySealItem.propTypes = {
  applicant: PropTypes.shape({
    cbp_security_seal: PropTypes.shape({
      id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      zone_requested: PropTypes.string,
      spec_desc: PropTypes.string.isRequired,
    }),
    lock_profile: PropTypes.bool,
  }).isRequired,
  setCurrentView: PropTypes.func,
};

export default CbpSecuritySealItem;