// app/javascript/contexts/LocationDataContext.js

import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

// Create the context
const LocationDataContext = createContext();

// Create a provider
export const LocationDataProvider = ({ states, countries, children }) => {
  return (
    <LocationDataContext.Provider value={{ states, countries }}>
      {children}
    </LocationDataContext.Provider>
  );
};

// Custom hook to use the context
export const useLocationData = () => {
  return useContext(LocationDataContext);
};

LocationDataProvider.propTypes = {
  states: PropTypes.arrayOf(PropTypes.string).isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};