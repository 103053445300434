import React from 'react';
import { useFormData } from '../../../contexts/FormDataContext';
import { useLocationData } from '../../../contexts/LocationDataContext';
import { useTranslation } from 'react-i18next';

const ResidenceSection = ({ onClearError, error }) => {
  const { formData, handleChange } = useFormData();
  const { states } = useLocationData();
  const translationLocation = "activerecord.attributes.form3078"
  const { t } = useTranslation();

  const addRow = () => {
    const updatedResidences = [
      ...formData.residences,
      { from: '', to: '', address: '', city: '', state: '' },
    ];
    handleChange({
      target: {
        name: 'residences',
        value: updatedResidences,
      },
    });
  };

  const removeRow = (index) => {
    const updatedResidences = formData.residences.filter((_, i) => i !== index);
    handleChange({
      target: {
        name: 'residences',
        value: updatedResidences,
      },
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedResidences = formData.residences.map((residence, i) =>
      i === index ? { ...residence, [field]: value } : residence
    );
    handleChange({
      target: {
        name: 'residences',
        value: updatedResidences,
      },
    });
  };

  return (
    <>
      <div className="row" name="residences">
        <div className="col-lg-12">
          <label>
            22. {t(translationLocation + ".q_22")}
          </label>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered border-dark">
          <thead>
            <tr>
              <th colSpan="2" className="text-center">{t("my.words.dates")}</th>
              <th className="text-center">{t("my.form3078.p3")}</th>
              <th className="text-center">{t("my.words.city")}</th>
              <th className="text-center">{t("my.words.state")}</th>
              <th className="text-center">{t("my.words.actions")}</th>
            </tr>
            <tr>
              <th className="text-center">From</th>
              <th className="text-center">To</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formData?.residences?.map((residence, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From"
                    value={residence.from}
                    onChange={(e) =>
                      handleInputChange(index, 'from', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To"
                    value={residence.to}
                    onChange={(e) =>
                      handleInputChange(index, 'to', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Number and Street"
                    value={residence.address}
                    onChange={(e) =>
                      handleInputChange(index, 'address', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    value={residence.city}
                    onChange={(e) =>
                      handleInputChange(index, 'city', e.target.value)
                    }
                  />
                </td>
                <td>
                  <select
                    className="form-control"
                    value={residence.state}
                    onChange={(e) =>
                      handleInputChange(index, 'state', e.target.value)
                    }
                  >
                    <option value="">Select State</option>
                    {states.map((state, i) => (
                      <option key={i} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={() => removeRow(index)}
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          type="button"
          className="btn btn-secondary mt-2"
          onClick={addRow}
        >
          ➕ Add Residence
        </button>
      </div>
      {console.log({error})}
      {error && (
        <small className="text-danger">{error}</small>
      )}
    </>
  );
};

export default ResidenceSection;