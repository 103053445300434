import React, { useState, useRef, useEffect } from 'react';
import { useFormData } from '../../../contexts/FormDataContext';
import FormField from '../../shared/FormField';
import SignatureCanvas from 'react-signature-canvas';
import { Modal, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formFields } from './formFields';
import { submitFormWithSignature } from '../../../api/form3078';

const ApplicantInfoSection = () => {
  const { formData, handleChange } = useFormData();
  const { t } = useTranslation();
  const translationLocation = "activerecord.attributes.form3078";
  const fields = formFields(t, translationLocation);

  const groupedFields = fields.reduce((acc, field) => {
    acc[field.row] = acc[field.row] || [];
    acc[field.row].push(field);
    return acc;
  }, {});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const sigCanvas = useRef(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const openModal = () => {
    const validationErrors = {};
    let hasError = false;

    // Clear previous errors
    setErrors({});

    // Run validations for all fields
    fields.forEach((field) => {
      if (field.validate) {
        const error = field.validate(formData[field.name]);
        if (error) {
          console.log({validationErrors, "value": validationErrors[field.name], name: field.name, error})
          validationErrors[field.name] = error;
          hasError = true;
        }
      }
    });

    setErrors(validationErrors);

    if (hasError) {
      const firstErrorField = Object.keys(validationErrors)[0];
      const element = document.querySelector(`[name="${firstErrorField}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.focus();
      }
      return;
    }

    setIsModalOpen(true);
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[fieldName];
      return updatedErrors;
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveSignature = async () => {
    const dataUrl = sigCanvas.current.toDataURL();
    closeModal();

    try {
      const responseData = await submitFormWithSignature(formData, dataUrl);
      setConfirmationMessage(
        "Your form has been submitted successfully. You can download a copy from your dashboard."
      );
      
      console.log('Form submitted successfully:', responseData);
    } catch (error) {
      setConfirmationMessage("There was an error submitting your form. Please try again.");
      console.error('Error while submitting form:', error);
    }
  };

  const clearSignature = () => sigCanvas.current.clear();

  return (
    <>
    {/* Display confirmation message */}
    {confirmationMessage && (
        <Alert
          variant="success"
          onClose={() => setConfirmationMessage(null)}
          dismissible
        >
          {confirmationMessage}
        </Alert>
      )}
      {Object.values(groupedFields).map((fields, index) => (
        <div className="row" style={{ paddingBottom: "12px" }} key={`row-${index}`}>
          {fields.map((field) => {
            if (field.type === "section" && field.component) {
              const Component = field.component;
              return (
                <div
                  className={`col-lg-${field.col || 12} border-top border-dark ${field.col === 12 ? '' : 'border-right'} md-hide`}
                  key={field.id}
                >
                  <Component
                    value={formData[field.name]}
                    onChange={handleChange}
                    onClearError={clearError}
                    error={errors[field.name]}
                  />
                </div>
              );
            } else if (field.type === "group") {
              // Render grouped fields
              return (
                <div className={`col-lg-${field.col || 12} border-top border-dark ${field.col === 12 ? '' : 'border-right'} md-hide`} key={field.id}>
                  {field.fields.map((subField) => (
                    <div
                      className={`col-lg-${subField.col || 12}`}
                      key={subField.id}
                    >
                      <FormField
                        id={subField.id}
                        label={subField.label}
                        name={subField.name}
                        type={subField.type}
                        value={formData[subField.name] || ""}
                        onChange={handleChange}
                        options={subField.options}
                        placeholder={subField.placeholder}
                        validate={subField.validate}
                        formatter={subField.formatter}
                        onClearError={clearError}
                        error={errors[subField.name]}
                      />
                      {errors[subField.name] && (
                        <small className="text-danger">{errors[subField.name]}</small>
                      )}
                    </div>
                  ))}
                </div>
              );
            }

            // Render standard fields
            return (
              <div
                className={`col-lg-${field.col || 12} border-top border-dark ${field.col === 12 ? '' : 'border-right'} md-hide`}
                key={field.id}
              >
                <FormField
                  id={field.id}
                  label={field.label}
                  name={field.name}
                  type={field.type}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  options={field.options}
                  placeholder={field.placeholder}
                  validate={field.validate}
                  formatter={field.formatter}
                  onClearError={clearError}
                  error={errors[field.name]}
                />
                {errors[field.name] && (
                  <small className="text-danger">{errors[field.name]}</small>
                )}
              </div>
            );
          })}
        </div>
      ))}

      {/* Notices */}
      <div className="row">
        <div className="col-lg-12 border-top border-dark pt-3">
          <p className="small">
            {t("my.form3078.p7")}
          </p>
          <p className="small">
            {t("my.form3078.p8")}
          </p>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="row mt-4">
        <div className="col-6 text-left">
          <button className="btn btn-secondary">← {t("my.words.dashboard")}</button>
        </div>
        <div className="col-6 text-right">
          <button className="btn btn-primary" onClick={openModal} type="button">
            {t("my.buttons.sign")} →
          </button>
        </div>
      </div>

      {/* Modal for Signature */}
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("my.words.applicant_signature")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("my.form3078.statement")}</p>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              width: 450,
              height: 200,
              className: 'sigCanvas',
              style: { border: '1px solid #ccc' },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={clearSignature}>
            Clear
          </Button>
          <Button variant="primary" onClick={saveSignature}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApplicantInfoSection;