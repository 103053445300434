import React, { useState } from "react";
import { useTable } from "react-table";
import { Card, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, ButtonGroup, FormControl, InputLabel, MenuItem, Select, Alert } from "@mui/material";


const OrderEntryPreview = ({data, road_markings, ids, customerIDs, hasApproved, daily_logs, back_url}) => {

  const parsed_road_markings = JSON.parse(road_markings)

  const [tableData, setTableData] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workOrder, setWorkOrder] = useState("");
  const [roadMarking, setRoadMarking] = useState("");
  const [number, setNumber] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

 const handleCreateOrderEntry = () => {
    setIsProcessing(true)
    fetch('/admin/order_entries/process_order_entry', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json' // Make sure the server expects JSON
      },
      body: JSON.stringify({ ids: ids.join(",") }) // Send the data as a JSON string
    })
    .then(response => response.json()) // Handle the response as JSON
    .then(data => {
      console.log("Order Entries created successfully");

      alertify.success(data.message); // Show the success message
      setTimeout(() => {
        setIsProcessing(false)
        window.location = `/admin/order_entries`; // Navigate to the order entry page after a short delay
      }, 2000); // Adjust the delay as needed (2 seconds in this case)

    })
    .catch(error => {
      console.error('Error:', error); // Handle any error
    });
  }

  const handleRoadMarkingChange = (event) => {
    setRoadMarking(event.target.value)
  }

  const handleWorkOrderChange = (event) => {
    setWorkOrder(event.target.value);
  };

  const handleNumberChange = (event) => {
    setNumber(event.target.value);
  };

  const handleUnitsChange = (index, value) => {
    const newData = [...tableData];
    newData[index].units = value;
    setTableData(newData);
    updateMarkingAttr(newData[index].id, newData[index].attr_name, value, newData[index].description)
  };

  const handleOnClickClose = () => {
    setNumber("");
    setWorkOrder("");
    setRoadMarking("");
    setIsModalOpen(false);
  }

  const updateMarkingAttr = async (id, attr, value, description=null) => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

      const response = await fetch(`/admin/markings/${id}.json`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken, // Include CSRF token
        },
        body: JSON.stringify({
          marking: {
            [attr]: value,
          },
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update marking attribute");
      } else {
        if(description !== null) {
          alertify.success(`${description} was successfully updated.`)
        }
      }
    } catch (error) {
      console.error("Error updating marking attribute:", error);
    }
  };

  const handleSubmitForm = () => {
    updateMarkingAttr(workOrder, roadMarking, number)
    const rowInTheTable = tableData.find(row => row.id === workOrder && row.attr_name === roadMarking);
    let newData, message;
    if(rowInTheTable) {
      newData = [...tableData];
      const updatedRow = newData.find(row => row.id === workOrder && row.attr_name === roadMarking)
      updatedRow.units = number;
      message = `${rowInTheTable.description} was successfully updated.`;
    } else {
      const itemSameId = data.find(el => el.id === workOrder)
      newData = [...tableData, {
        "job_number": itemSameId.job_number,
        "description": parsed_road_markings[roadMarking].description,
        "units": number,
        "units_of_measure": parsed_road_markings[roadMarking].units_of_measure,
        "date": itemSameId.date,
        "crew_name": itemSameId.crew_name,
        "upload_account": `${itemSameId.job_number}.${parsed_road_markings[roadMarking].account}`,
        "account_number": parsed_road_markings[roadMarking].account,
        "id": workOrder,
        "attr_name": "cross_walks"
      }]
      message = `New row for ${parsed_road_markings[roadMarking].description} was successfully added.`;
    }
    setTableData(newData)
    handleOnClickClose()
    alertify.success(message)
  }

  const columns = React.useMemo(
    () => [
      { Header: "Job Number", accessor: "job_number" },
      { Header: "Description", accessor: "description" },
      {
        Header: "Units",
        accessor: "units",
        Cell: ({ row }) => (
          <TextField
            type="number"
            defaultValue={tableData[row.index].units}
            onBlur={(e) => handleUnitsChange(row.index, e.target.value)} // Update only when user leaves the field
            size="small"
            sx={{width: "80px"}}
          />
        ),
      },
      { Header: "Units of Measure", accessor: "units_of_measure" },
      { Header: "Date", accessor: "date" },
      { Header: "Crew Name", accessor: "crew_name" },
      { Header: "Upload Account", accessor: "upload_account" },
      { Header: "Account Number", accessor: "account_number" },
    ],
    [tableData]
  );
  
  const customerKeys = Object.keys(customerIDs)
  
  const hasCustomerNull = Object.values(customerIDs).includes(null);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableData });

  const disabled = hasApproved || isProcessing || hasCustomerNull;

  const wo_options = tableData.map((el) => [`${el.job_number} - ${el.crew_name}`, el.id])

  const unique_wo_options = Array.from(
    new Map(wo_options.map(([key, value]) => [key, [key, value]])).values()
  );

  const rm_options = Object.keys(parsed_road_markings).map(key => [parsed_road_markings[key].description, key])

  const isFormValid = workOrder && roadMarking && number;


  return  (
    <div style={{minHeight: "60vh"}}>
      <div style={{padding: "20px 0", display: "flex", justifyContent: "space-between"}}>
         <ButtonGroup variant="contained" color="secondary">
          <Button variant="contained" component="a" href={back_url}>
            Go Back
          </Button>
          <Button variant="contained" sx={{ backgroundColor: '#00aeef', borderRadius: "3px", '&:hover': { backgroundColor: '#008bb2' } }} onClick={() => setIsModalOpen(true)} disabled={disabled}>
            Add New
          </Button>
        </ButtonGroup>
        <Button variant="contained" sx={{ backgroundColor: '#00c851', borderRadius: "3px", '&:hover': { backgroundColor: '#00a541' } }} disabled={disabled} onClick={handleCreateOrderEntry}>
          Create Order Entry
        </Button>
      </div>

      <Card className="p-4">

        {/* Modal */}
        <Dialog open={isModalOpen} onClose={handleOnClickClose}>
          <DialogTitle>Add New Row</DialogTitle>
          <DialogContent sx={{width: "400px"}}>
            <FormControl fullWidth sx={{margin: "15px 0"}}>
              <InputLabel>Select work order</InputLabel>
              <Select value={workOrder} onChange={handleWorkOrderChange}>
                {
                  unique_wo_options.map((value, index) => <MenuItem key={index} value={value[1]}>{value[0]}</MenuItem>)

                }
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Select road marking</InputLabel>
              <Select value={roadMarking} onChange={handleRoadMarkingChange}>
                {
                  rm_options.map((value, index) => <MenuItem key={index} value={value[1]}>{value[0]}</MenuItem>)

                }
              </Select>
              <FormControl fullWidth sx={{marginTop: "15px"}}>
                </FormControl>
                         <TextField
                    label="Enter a Number"
                    type="number"
                    value={number}
                    onChange={handleNumberChange}
                    fullWidth
                    inputProps={{ min: 0, step: 1 }} // Restrict to integers
                  />
              </FormControl>
          </DialogContent>
          <DialogActions>
            <ButtonGroup color="secondary" sx={{marginRight: "15px", marginBottom: "20px"}}>
              <Button onClick={handleSubmitForm} disabled={!isFormValid}>
                Submit
              </Button>
              <Button onClick={handleOnClickClose}>
                Close
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>

        {
          hasCustomerNull ? (<Alert severity="warning">
            You have selected multiple work orders associated with different customer IDs. Please ensure that all work orders have the same customer ID, or unselect the daily logs with differing customer IDs.
            <p>For reference:</p>
            <ul>
              {
              customerKeys.map(key => <li key={key}><b>{key}</b> has {customerIDs[key] === null ? <b>a null </b> : ''}customer ID <b>{customerIDs[key]}</b></li>)
              }
            </ul>
          </Alert>) : 
          hasApproved ? (
          <Alert severity="warning">
            You need to select daily logs that weren’t processed yet.
            <p>For reference:</p>
            <ul>
              {
              Object.keys(daily_logs).map(key => <li key={key}><b>{key}</b> {daily_logs[key] === "processed" ? 'has been' : 'is'} <b>{daily_logs[key]}</b></li>)
              }
            </ul>
          </Alert>
        ) : (
          <table {...getTableProps()} className="w-full border-collapse">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="p-2 text-left" style={{padding: "10px", fontWeight: "bold", textTransform: "uppercase"}}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-b hover:bg-gray-100">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="p-2" style={{padding: "10px"}}>
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        )

        }
        
      </Card>
    </div>
  );
};

export default OrderEntryPreview;
