import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleCard from '../shared/CollapsibleCard';

const Form3078Item = ({ setCurrentView }) => {
  const renderFormLink = () => {
    return (
      <button onClick={() => setCurrentView('3078')}><i className="far fa-hand-point-up"></i> Custom CBP 3078</button>
    );
  };

  return (
    <li className="px-0 dash-li">
      <CollapsibleCard
        collapseId="Form3078"
        header={renderFormLink()}
      >
        <div className="card-body p-0">
          <table className="table">
            <tbody>
              <tr>
                <th className="font-weight-bold border-bottom-0">Date</th>
                <th className="font-weight-bold border-bottom-0">Type of Activity</th>
              </tr>
              <tr>
              </tr>
            </tbody>
          </table>
        </div>
      </CollapsibleCard>
    </li>
  );
};

// Define PropTypes for the component
Form3078Item.propTypes = {
  setCurrentView: PropTypes.func,
};

export default Form3078Item;