import React, { createContext, useState, useEffect, useContext } from 'react';
import { getSensitiveFieldNames, formFields } from '../components/Forms/3078/formFields';

const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
  const sensitiveFields = getSensitiveFieldNames();

  // Dynamically initialize formData
  const initializeFormData = () => {
    const initialData = {
      q_1: "",
      applicationDate: Date()
    };
  
    formFields(() => {}, "").forEach((field) => {
      if (field.type === 'group' && field.fields) {
        field.fields.forEach((subField) => {
          initialData[subField.name] = '';
        });
      } else if (field.type === 'section' && field.component) {
        if (field.component.name === "ResidenceSection") {
          initialData['residences'] = [
            { from: '', to: '', address: '', city: '', state: '' },
          ];
        } else if (field.component.name === "PreviousEmploymentSection") {
          initialData['employments'] = [
            { employer: '', occupation: '', from: '', to: '' },
          ];
        } else if (field.component.name === "ConvictionHistorySection") {
          initialData['convictions'] = [
            { charge: '', date: '', place: '', disposition: '', court: '' },
          ];
        }
      } else if (field.name) {
        initialData[field.name] = '';
      }
    });
    
    return initialData;
  };

  const [formData, setFormData] = useState(initializeFormData);

  useEffect(() => {
    const savedData = localStorage.getItem('applicantFormData');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setFormData((prevData) => {
        const mergedData = { ...prevData, ...parsedData };
        return mergedData;
      });
    }
  }, []);

  useEffect(() => {
    const filteredData = Object.keys(formData).reduce((acc, key) => {
      if (!sensitiveFields.includes(key)) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    localStorage.setItem('applicantFormData', JSON.stringify(filteredData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <FormDataContext.Provider value={{ formData, setFormData, handleChange }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => {
  return useContext(FormDataContext);
};