import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormData } from '../../../contexts/FormDataContext';

const ActivitySection = () => {
  const translationLocation = "activerecord.attributes.form3078";
  const { t } = useTranslation();
  const { formData, handleChange } = useFormData(); // Access context

  const handleCheckboxChange = (field, isChecked) => {
    const currentValues = formData.q_1 ? formData.q_1.split(', ') : [];
    const updatedValues = isChecked
      ? [...currentValues, field]
      : currentValues.filter((value) => value !== field);

    handleChange({
      target: {
        name: 'q_1',
        value: updatedValues.join(', '),
      },
    });
  };

  const handleDateChange = (event) => {
    handleChange({
      target: {
        name: 'applicationDate',
        value: event.target.value,
      },
    });
  };

  return (
    <div className="row pt-2 pb-3">
      <div className="col-lg-9 border-top border-dark border-right md-hide">
        <label>1. {t(translationLocation + ".q_1")}</label>
        <div>
          <div className="form-check">
            <input
              type="checkbox"
              id="cartman"
              className="form-check-input"
              checked={formData.q_1?.includes('Cartman/Lighterman') || false}
              onChange={(e) => handleCheckboxChange('Cartman/Lighterman', e.target.checked)}
            />
            <label htmlFor="cartman" className="form-check-label">Cartman/Lighterman</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="broker"
              className="form-check-input"
              checked={formData.q_1?.includes("Broker's Employee") || false}
              onChange={(e) => handleCheckboxChange("Broker's Employee", e.target.checked)}
            />
            <label htmlFor="broker" className="form-check-label">Broker's Employee</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="cbp-security"
              className="form-check-input"
              checked={formData.q_1?.includes('CBP Security Area Identification') || false}
              onChange={(e) => handleCheckboxChange('CBP Security Area Identification', e.target.checked)}
            />
            <label htmlFor="cbp-security" className="form-check-label">CBP Security Area Identification</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="warehouse-officer"
              className="form-check-input"
              checked={formData.q_1?.includes('Warehouse Officer or Employee') || false}
              onChange={(e) => handleCheckboxChange('Warehouse Officer or Employee', e.target.checked)}
            />
            <label htmlFor="warehouse-officer" className="form-check-label">Warehouse Officer or Employee</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="container-station"
              className="form-check-input"
              checked={formData.q_1?.includes('Container Station Employee') || false}
              onChange={(e) => handleCheckboxChange('Container Station Employee', e.target.checked)}
            />
            <label htmlFor="container-station" className="form-check-label">Container Station Employee</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="foreign-trade-zone"
              className="form-check-input"
              checked={formData.q_1?.includes('Foreign Trade Zone Employee') || false}
              onChange={(e) => handleCheckboxChange('Foreign Trade Zone Employee', e.target.checked)}
            />
            <label htmlFor="foreign-trade-zone" className="form-check-label">Foreign Trade Zone Employee</label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              id="ces"
              className="form-check-input"
              checked={formData.q_1?.includes('CES Employee') || false}
              onChange={(e) => handleCheckboxChange('CES Employee', e.target.checked)}
            />
            <label htmlFor="ces" className="form-check-label">CES Employee</label>
          </div>
        </div>
      </div>
      <div className="col-lg-3 border-top border-dark md-hide">
        <label htmlFor="applicationDate">2. {t(translationLocation + ".date")}</label>
        <input
          type="date"
          id="applicationDate"
          className="form-control"
          value={formData.applicationDate || new Date().toISOString().substr(0, 10)}
          onChange={handleDateChange}
        />
      </div>
    </div>
  );
};

export default ActivitySection;