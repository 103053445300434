const formatSSN = (value) => {
  // Remove all non-numeric characters
  const numericValue = value.replace(/\D/g, '');

  // Format the string as XXX-XX-XXXX
  if (numericValue.length <= 3) {
    return numericValue;
  } else if (numericValue.length <= 5) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}`;
  } else {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3, 5)}-${numericValue.slice(5, 9)}`;
  }
};

const formatPhoneNumber = (value) => {
  if (!value) return value;

  // Remove all non-numeric characters
  const cleaned = value.replace(/\D+/g, '');

  // Check the length and format accordingly
  if (cleaned.length <= 3) {
    return cleaned;
  } else if (cleaned.length <= 6) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
  }
};

export const formatter = {
  formatSSN,
  formatPhoneNumber,
}