import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ApplicantDashboard from './ApplicantDashboard/ApplicantDashboard';
import styles from './Dashboard.module.css';
import IdentificationForm from './Forms/3078/IdentificationForm';
import "../styles/dashboard.css"
import { FormDataProvider } from '../contexts/FormDataContext';
import { LocationDataProvider } from '../contexts/LocationDataContext';
import 'bootstrap/dist/css/bootstrap.min.css';

const TSPortalReact = ({ employmentExperiences, states, countries }) => {
  const { t } = useTranslation();

  const getInitialView = () => {
    const params = new URLSearchParams(window.location.search);
    const formParam = params.get('form');
    if (formParam) {
      return formParam; // Use the query parameter value, e.g., 'form3078'
    }
    return sessionStorage.getItem('currentView') || 'dashboard'; // Default to 'dashboard'
  };

  const [currentView, setCurrentView] = useState(getInitialView);

  useEffect(() => {
    sessionStorage.setItem('currentView', currentView);
  }, [currentView]);

  const renderContent = () => {
    switch (currentView) {
      case '3078':
        return <IdentificationForm setCurrentView={setCurrentView} />;
      default:
        return (
          <div className={`${styles.dashboardContainer} container`}>
            <div className="row">
              <div className="col-12">
                <ApplicantDashboard
                  // applicant={applicant}
                  // employmentExperiences={employmentExperiences}
                  setCurrentView={setCurrentView}
                />
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <LocationDataProvider states={states} countries={countries}>
      <FormDataProvider>
        {renderContent()}
      </FormDataProvider>
    </LocationDataProvider>
  );
};

TSPortalReact.propTypes = {
  // applicant: PropTypes.object.isRequired,
  employmentExperiences: PropTypes.arrayOf(PropTypes.object),
  countries: PropTypes.arrayOf(PropTypes.string),
  states: PropTypes.arrayOf(PropTypes.string),
};

export default TSPortalReact;