import React from 'react';

const FormField = ({
  id,
  label,
  name,
  type = 'text',
  value = '',
  onChange,
  options = [],
  placeholder = '',
  formatter,
  onClearError,
  error,
}) => {
  const handleChange = (e) => {
    let formattedValue = e.target.value;

    if (formatter) {
      formattedValue = formatter(formattedValue);
    }

    onChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });

    if (onClearError && error) {
      onClearError(name);
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      {type === 'select' ? (
        <select
          id={id}
          name={name}
          className="form-control"
          value={value}
          onChange={handleChange}
        >
          <option value="">{placeholder || 'Select an option'}</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : type === 'radio' ? (
        <div>
          {options.map((option, index) => (
            <div key={`${id}-${index}`} className="form-check">
              <input
                type="radio"
                id={`${id}-${option.value}`}
                name={name}
                className="form-check-input"
                value={option.value}
                checked={value === option.value}
                onChange={handleChange}
              />
              <label
                htmlFor={`${id}-${option.value}`}
                className="form-check-label"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          className="form-control"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default FormField;