import React from 'react';
import PropTypes from 'prop-types';

const BackButtonHeader = ({ setCurrentView }) => {
  return (
    <div className="back-button-header">
      <button onClick={() => setCurrentView('dashboard')} className="btn btn-link">
        ← Back to Dashboard
      </button>
      <style>
        {`
          .back-button-header {
            display: flex;
            align-items: center;
            padding: 10px 0;
          }
          h2 {
            margin-left: 10px;
          }
        `}
      </style>
    </div>
  );
};

BackButtonHeader.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
};

export default BackButtonHeader;