import ResidenceSection from './ResidenceSection';
import PreviousEmploymentSection from './PreviousEmploymentSection';
import ConvictionHistorySection from './ConvictionHistorySection';
import { validations } from '../utils/validations';
import { formatter } from '../utils/formatter';

export const formFields = (t, translationLocation) => [
  { id: "name", label: `3. ${t(translationLocation + ".name")}`, name: "name", type: "text", col: 8, row: 1, validate: validations.validateName },
  { id: "ssn", label: `4. ${t(translationLocation + ".ssn")}`, name: "ssn", type: "text", col: 4, row: 1, validate: validations.validateSSN, formatter: formatter.formatSSN, sensitive: true },
  { id: "q_5", label: `5. ${t(translationLocation + ".q_5")}`, name: "q_5", type: "text", col: 8, row: 2 },
  { id: "dob", label: `6. ${t(translationLocation + ".dob")}`, name: "dob", type: "date", col: 4, row: 2, sensitive: true, validate: validations.validateDOB },
  { id: "homeAddress", label: `7. ${t(translationLocation + ".home_address")}`, name: "homeAddress", type: "text", col: 6, row: 3, sensitive: true },
  { id: "employerAddress", label: `8. ${t(translationLocation + ".q_8")}`, name: "employerAddress", type: "text", col: 6, row: 3, sensitive: true },
  { id: "homePhone", label: `9. ${t(translationLocation + ".phone")}`, name: "homePhone", type: "text", col: 6, row: 4, validate: validations.validatePhoneNumber, formatter: formatter.formatPhoneNumber, sensitive: true },
  { id: "bu_phone", label: `10. ${t(translationLocation + ".bu_phone")}`, name: "bu_phone", type: "text", col: 6, row: 4, validate: validations.validatePhoneNumber, formatter: formatter.formatPhoneNumber, sensitive: true },
  { id: "q_11", label: `11. ${t(translationLocation + ".q_11")}`, name: "q_11", type: "text", col: 4, row: 5 },
  { id: "q_12", label: `12. ${t(translationLocation + ".q_12")}`, name: "q_12", type: "select", options: ["5'0\"", "5'1\"", "5'2\""], col: 2, row: 5 },
  { id: "q_13", label: `13. ${t(translationLocation + ".q_13")}`, name: "q_13", type: "text", col: 2, row: 5 },
  { id: "q_14", label: `14. ${t(translationLocation + ".q_14")}`, name: "q_14", type: "select", options: ["Black", "Brown", "Blonde", "Red"], col: 2, row: 5 },
  { id: "q_15", label: `15. ${t(translationLocation + ".q_15")}`, name: "q_15", type: "select", options: ["Blue", "Brown", "Green", "Hazel"], col: 2, row: 5 },
  { id: "q_16", label: `16. ${t(translationLocation + ".q_16")}`, name: "q_16", type: "text", col: 12, row: 6 },
  { id: "q_17", label: `17. ${t(translationLocation + ".q_17")}`, name: "q_17", type: "text", col: 6, row: 7 },
  { id: "q_18", label: `18. ${t(translationLocation + ".q_18")}`, name: "q_18", type: "text", col: 6, row: 7 },
  { id: "q_19", label: `19. ${t(translationLocation + ".q_19")}`, name: "q_19", type: "radio", options: [{ value: "yes", label: "Yes" }, { value: "no", label: "No (Skip Items 20 and 21)" }], col: 6, row: 8 },
  { id: "q_20", label: `20. ${t(translationLocation + ".q_20")}`, name: "q_20", type: "radio", options: [{ value: "yes", label: "Yes (If Yes, explain in Item 21)" }, { value: "no", label: "No (Skip Item 21)" }], col: 6, row: 8 },
  { id: "q_21", label: `21. ${t(translationLocation + ".q_21")}`, name: "q_21", type: "textarea", col: 12, row: 9 },
  { id: "residenceSection", type: "section", component: ResidenceSection, row: 10, name: "residences", validate: validations.validateResidencesSpanningFiveYears },
  { id: "q_23", label: `23. ${t(translationLocation + ".q_23")}`, name: "q_23", type: "radio", options: [{ value: "yes", label: "Yes" }, { value: "no", label: "No (Skip Items 24-28)" }], col: 6, row: 11, validate: validations.validateMilitaryService },
  { id: "q_24", label: `24. ${t(translationLocation + ".q_24")}`, name: "q_24", type: "text", col: 6, row: 11 },
  { id: "q_25", label: `25. ${t(translationLocation + ".q_25")}`, name: "q_25", type: "text", col: 6, row: 12 },
  { id: "q_26", label: `26. ${t(translationLocation + ".q_26")}`, name: "q_26", type: "text", col: 3, row: 12 },
  { id: "q_27", label: `27. ${t(translationLocation + ".q_27")}`, name: "q_27", type: "text", col: 3, row: 12 },
  { id: "q_28", label: `28. ${t(translationLocation + ".q_28")}`, name: "q_28", type: "textarea", col: 12, row: 13 },
  { id: "q_29", label: `29. ${t(translationLocation + ".q_29")}`, name: "q_29", type: "radio", options: [{ value: "yes", label: "Yes (If Yes, explain details)" }, { value: "no", label: "No" }], col: 12, row: 14, validate: validations.validateIDCardApplication },
  { id: "q_29a", label: `29a. ${t(translationLocation + ".q_29a")}`, name: "q_29a", type: "textarea", col: 12, row: 15 },
  { id: "previousEmploymentSection", type: "section", component: PreviousEmploymentSection, row: 16 },
  { id: "q_31", label: `31. ${t(translationLocation + ".q_31")}`, name: "q_31", type: "radio", options: [{ value: "yes", label: "Yes" }, { value: "no", label: "No" }], col: 12, row: 17, validate: validations.validateConvictions },
  { id: "q_32", label: `32. ${t(translationLocation + ".q_32")}`, name: "q_32", type: "textarea", col: 12, row: 18 },
  { id: "convictionHistorySection", type: "section", component: ConvictionHistorySection, row: 19 },
  {
    id: "drugUseSection",
    type: "group",
    col: 8,
    row: 20,
    fields: [
      {
        id: "q_33",
        label: `33. ${t(translationLocation + ".q_33")}`,
        name: "q_33",
        type: "radio",
        options: [
          { value: "yes", label: "YES (If YES, explain below.)" },
          { value: "no", label: "No" },
        ],
        col: 8,
        sensitive: true,
        validate: validations.validateDrugUse
      },
      {
        id: "q_33a",
        label: `${t(translationLocation + ".q_33a")}`,
        name: "q_33a",
        type: "textarea",
        col: 12,
        sensitive: true,
      },
    ],
  },
  {
    id: "attachPhotograph",
    label: "34. ATTACH PHOTOGRAPH HERE",
    name: "photograph",
    type: "custom",
    col: 4,
    row: 20,
  },
];

export const getSensitiveFieldNames = () => {
  return formFields(() => {}, "").filter((field) => field.sensitive).map((field) => field.name);
};