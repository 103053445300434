import React from 'react';
import PropTypes from 'prop-types';

const CollapsibleCard = ({ header, collapseId, children }) => (
  <div className="card z-depth-0">
    <div className="card-header border-bottom-0" id={`${collapseId}Heading`}>
      <h5
        className="mb-0"
        data-bs-toggle="collapse"
        data-target={`#${collapseId}`}
        aria-expanded="true"
        aria-controls={collapseId}
      >
        {header}
      </h5>
    </div>
    <div id={collapseId} className="collapse" aria-labelledby={`${collapseId}Heading`} data-parent="#dashAccordion">
      <div className="card-body p-0">{children}</div>
    </div>
  </div>
);

CollapsibleCard.propTypes = {
  header: PropTypes.node.isRequired,
  collapseId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default CollapsibleCard;