import React from 'react';
import { useFormData } from '../../../contexts/FormDataContext';
import { useTranslation } from 'react-i18next';

const PreviousEmploymentSection = () => {
  const { formData, handleChange } = useFormData();
  const translationLocation = "activerecord.attributes.form3078";
  const { t } = useTranslation();

  const addRow = () => {
    const updatedEmployments = [
      ...formData.employments,
      { from: '', to: '', employer: '', occupation: '' },
    ];
    handleChange({
      target: {
        name: 'employments',
        value: updatedEmployments,
      },
    });
  };

  const removeRow = (index) => {
    const updatedEmployments = formData.employments.filter((_, i) => i !== index);
    handleChange({
      target: {
        name: 'employments',
        value: updatedEmployments,
      },
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedEmployments = formData.employments.map((employment, i) =>
      i === index ? { ...employment, [field]: value } : employment
    );
    handleChange({
      target: {
        name: 'employments',
        value: updatedEmployments,
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <label>
            30. {t(translationLocation + ".q_30")}
          </label>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered border-dark">
          <thead>
            <tr>
              <th colSpan="2" className="text-center">{t("my.words.dates")}</th>
              <th className="text-center">{t("my.form3078.p4")}</th>
              <th className="text-center">{t("my.words.occupation")}</th>
              <th className="text-center">{t("my.words.actions")}</th>
            </tr>
            <tr>
              <th className="text-center">{t("activerecord.attributes.background_check.date_from")}</th>
              <th className="text-center">{t("activerecord.attributes.background_check.date_to")}</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {formData?.employments?.map((employment, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="From"
                    value={employment.from}
                    onChange={(e) =>
                      handleInputChange(index, 'from', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="To"
                    value={employment.to}
                    onChange={(e) =>
                      handleInputChange(index, 'to', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Employer Name and Address"
                    value={employment.employer}
                    onChange={(e) =>
                      handleInputChange(index, 'employer', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Occupation"
                    value={employment.occupation}
                    onChange={(e) =>
                      handleInputChange(index, 'occupation', e.target.value)
                    }
                  />
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={() => removeRow(index)}
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          type="button"
          className="btn btn-secondary mt-2"
          onClick={addRow}
        >
          ➕ Add Employment
        </button>
      </div>
    </>
  );
};

export default PreviousEmploymentSection;