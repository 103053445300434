// General validation for "can't be blank"
const validateNotBlank = (value, fieldName = 'Field') => {
  if (!value || value.trim() === '') {
    return `${fieldName} can't be blank`;
  }
  return null; // No error
};

// Regex to allow only letters and spaces
const nameRegex = /^[A-Za-z\s]+$/;

const validateName = (value) => {
  const blankError = validateNotBlank(value, 'Name');
  if (blankError) return blankError;

  if (!nameRegex.test(value)) {
    return 'Name must contain only letters';
  }
  return null; // No error
};

// SSN validation
const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;

const validateSSN = (value) => {
  const blankError = validateNotBlank(value, 'SSN');
  if (blankError) return blankError;

  if (!ssnRegex.test(value)) {
    return 'SSN must follow the format XXX-XX-XXXX';
  }
  return null; // No error
};

// Phone number validation
const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;

const validatePhoneNumber = (value) => {
  const blankError = validateNotBlank(value, 'Phone number');
  if (blankError) return blankError;

  if (!phoneRegex.test(value)) {
    return 'Phone number must be in the format (XXX) XXX-XXXX.';
  }
  return null; // No error
};

const validateResidencesSpanningFiveYears = (residences) => {
  if (!Array.isArray(residences) || residences.length === 0) {
    return "You must provide at least 5 years of residence history.";
  }

  let totalDays = 0;

  residences.forEach((residence) => {
    const from = new Date(residence.from);
    const to = new Date(residence.to);

    if (isNaN(from) || isNaN(to)) {
      return "Each residence must have a valid 'From' and 'To' date.";
    }

    if (from > to) {
      return "The 'From' date must be earlier than the 'To' date.";
    }

    // Add duration in days to the total
    const durationInDays = (to - from) / (1000 * 60 * 60 * 24);
    totalDays += durationInDays;
  });

  if (totalDays < 1825) { // 5 years = 1825 days
    return "Your residence history must span at least 5 years.";
  }

  return null; // No error
};

// Specific validations for the new fields
const validateDOB = (value) => validateNotBlank(value, 'Date of birth');
const validateMilitaryService = (value) => validateNotBlank(value, 'Have you ever served in the Armed Services of the U.S.?');
const validateIDCardApplication = (value) => validateNotBlank(value, 'Have you ever applied for an identification card with U.S. Customs and Border Protection?');
const validateConvictions = (value) => validateNotBlank(value, 'Have you ever been convicted of any crime or offense?');
const validateDrugUse = (value) => validateNotBlank(value, 'Do you now use or have you ever used narcotic drugs?');

export const validations = {
  validateNotBlank,
  validateName,
  validateSSN,
  validatePhoneNumber,
  validateMilitaryService,
  validateIDCardApplication,
  validateConvictions,
  validateDrugUse,
  validateDOB,
  validateResidencesSpanningFiveYears
};