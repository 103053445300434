import { toSnakeCase } from '../utils/helper'

/**
 * Submits the form data along with the signature to the server.
 * @param {Object} formData - The form data to be submitted.
 * @param {string} signature - The data URL of the signature.
 * @returns {Promise<Object>} - The server's response data.
 */
export const submitFormWithSignature = async (formData, signature) => {
  const payload = {
    form3078: {
      ...toSnakeCase(formData),
      signature, // Include the signature as part of the payload
    },
  };

  const locale = document.documentElement.lang || 'en'; // Get the current locale
  const url = `/${locale}/forms-3078/create_pdf_and_email`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      // Parse and return the response data
      return await response.json();
    } else {
      // Throw an error for non-OK responses
      throw new Error(`Error submitting form: ${response.statusText}`);
    }
  } catch (error) {
    // Log and rethrow the error to be handled by the caller
    console.error('Network error:', error);
    throw error;
  }
};