import React from 'react';
import PropTypes from 'prop-types';
import { Form3078Item } from '../FormLinks';

const ApplicantDashboard = ({ setCurrentView }) => {
  return (
    <div className="card rounded-0 animated fadeIn delay-1s">
      <div className="card-body p-3">
        <div className="row">
          {/* Left Column with Applicant Info */}
          {/* <div className="col-lg-4">
            <div className="mx-auto pt-1 w-70">
              <div className="d-flex justify-content-center">
                {applicant.avatar ? (
                  <img src={applicant.avatar} alt="Applicant Avatar" style={{ width: '100%', maxWidth: '300px' }} />
                ) : (
                  <img
                    src={applicant.sex ? `/images/${applicant.sex}.png` : '/images/no-image.png'}
                    alt="Default Avatar"
                    className="border-0"
                    style={{ width: '100%', maxWidth: '300px' }}
                  />
                )}
              </div>
              <p className="lead font-weight-normal pt-4 mb-1">{applicant.first_name} {applicant.last_name}</p>
              {employmentExperiences.length > 0 && employmentExperiences[0].job_title && employmentExperiences[0].employer && (
                <>
                  <p className="mb-1">{employmentExperiences[0].job_title} at</p>
                  <p className="mb-1">{employmentExperiences[0].employer}</p>
                </>
              )}
              <p className="mb-1">{applicant.email}</p>
              {applicant.cell_phone && <p className="mb-1">{`${applicant.cell_phone} (mobile)`}</p>}
              {applicant.city && <p className="mb-1">{applicant.city}, {applicant.state} {applicant.postal_code}</p>}
            </div>
          </div> */}

          {/* Right Column with Accordion Sections */}
          <div className="col-lg-12">
            <div className="accordion" id="dashAccordion">
              <ul className="mb-0 check-list">
                <Form3078Item setCurrentView={setCurrentView} />
                {/* <CbpSecuritySealItem applicant={applicant} setCurrentView={setCurrentView} />
                <BackgroundCheckItem applicant={applicant} setCurrentView={setCurrentView} /> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="card-footer info-color text-white rounded-0">
        <h6 className="text-uppercase mb-0 font-weight-bold">
          Dedicated Service Professionals Delivering As <span className="p_rel">Promised <span className="p_abs f-12 t0">&reg;</span></span>
          <small className="d-block float-lg-right">
            <a href="/privacy-policy" className="text-white hover-underline">Privacy Policy</a> &nbsp;|&nbsp;
            <a href="/cookie-policy" className="text-white hover-underline">Cookie Policy</a> &nbsp;|&nbsp;
            <i className="far fa-copyright"></i> 2025
          </small>
        </h6>
      </div>
    </div>
  );
};

ApplicantDashboard.propTypes = {
  // applicant: PropTypes.object.isRequired,
  setCurrentView: PropTypes.func,
};

export default ApplicantDashboard;