import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleCard from '../shared/CollapsibleCard';

const BackgroundCheckItem = ({ applicant, setCurrentView }) => {
  const hasBackgroundCheck = Boolean(applicant.background_check);

  const renderEditLink = () => {
    if (!applicant.lock_profile && applicant.ccs_division && hasBackgroundCheck) {
      return (
        <small className="float-right">
          <a
            href={`/edit_background_check/${applicant.background_check.id}`}
            className="text-info btn-link"
          >
            Edit
          </a>
        </small>
      );
    }
    return null;
  };

  const renderDownloadLink = () => {
    if (applicant.ccs_division) {
      return applicant.background_check.aws_key ? (
        <a
          href={`/show_pdf_background_checks?key=${applicant.background_check.aws_key}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link text-info"
        >
          Download
        </a>
      ) : null;
    } else {
      return (
        <a
          href={`/background_check/${applicant.background_check.id}.pdf`}
          className="btn-link text-info"
        >
          Download
        </a>
      );
    }
  };

  const headerContent = hasBackgroundCheck ? (
    <>
      <i className="far fa-check-circle green-text"></i> Background Check
      {renderEditLink()}
    </>
  ) : (
    <>
      <i className="far fa-circle"></i>Background Check
    </>
  );

  return (
    <li className="px-0 dash-li">
      <CollapsibleCard collapseId="bgCheckForm" header={headerContent}>
        {hasBackgroundCheck && (
          <div className="card-body p-0">
            <table className="table">
              <tbody>
                <tr>
                  <th className="font-weight-bold border-bottom-0">Date</th>
                  <td className="font-weight-normal">
                    {new Date(applicant.background_check.created_at).toLocaleDateString()}
                  </td>
                </tr>
                <tr>
                  <th className="font-weight-bold border-bottom-0">PDF</th>
                  <td className="font-weight-normal">{renderDownloadLink()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </CollapsibleCard>
    </li>
  );
};

BackgroundCheckItem.propTypes = {
  applicant: PropTypes.shape({
    background_check: PropTypes.shape({
      created_at: PropTypes.string,
      aws_key: PropTypes.string,
      id: PropTypes.number,
    }),
    lock_profile: PropTypes.bool,
    ccs_division: PropTypes.bool,
  }).isRequired,
  setCurrentView: PropTypes.func,
};

export default BackgroundCheckItem;