import React from 'react';
import { useFormData } from '../../../contexts/FormDataContext';
import { useTranslation } from 'react-i18next';

const ConvictionHistorySection = () => {
  const { formData, handleChange } = useFormData();
  const translationLocation = "activerecord.attributes.form3078";
  const { t } = useTranslation();

  const addRow = () => {
    const updatedConvictions = [
      ...formData.convictions,
      { date: '', place: '', charge: '', court: '', disposition: '' },
    ];
    handleChange({
      target: {
        name: 'convictions',
        value: updatedConvictions,
      },
    });
  };

  const removeRow = (index) => {
    const updatedConvictions = formData.convictions.filter((_, i) => i !== index);
    handleChange({
      target: {
        name: 'convictions',
        value: updatedConvictions,
      },
    });
  };

  const handleInputChange = (index, field, value) => {
    const updatedConvictions = formData.convictions.map((conviction, i) =>
      i === index ? { ...conviction, [field]: value } : conviction
    );
    handleChange({
      target: {
        name: 'convictions',
        value: updatedConvictions,
      },
    });
  };

  return (
    <div className="conviction-history-section">
      <div className="table-responsive">
        <table className="table table-bordered border-dark">
          <thead>
            <tr>
              <th className="text-center">{t("my.words.date")}</th>
              <th className="text-center">{t("my.words.place")}</th>
              <th className="text-center">{t("my.words.charge")}</th>
              <th className="text-center">{t("my.words.court")}</th>
              <th className="text-center">{t("my.form3078.p5")}</th>
              <th className="text-center">{t("my.words.actions")}</th>
            </tr>
          </thead>
          <tbody>
            {formData?.convictions?.map((conviction, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="mm/dd/yyyy"
                    value={conviction.date}
                    onChange={(e) =>
                      handleInputChange(index, 'date', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Place"
                    value={conviction.place}
                    onChange={(e) =>
                      handleInputChange(index, 'place', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Charge"
                    value={conviction.charge}
                    onChange={(e) =>
                      handleInputChange(index, 'charge', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Court"
                    value={conviction.court}
                    onChange={(e) =>
                      handleInputChange(index, 'court', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Final Disposition"
                    value={conviction.disposition}
                    onChange={(e) =>
                      handleInputChange(index, 'disposition', e.target.value)
                    }
                  />
                </td>
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={() => removeRow(index)}
                  >
                    🗑️
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button type="button" className="btn btn-secondary mt-2" onClick={addRow}>
          ➕ Add Conviction
        </button>
      </div>
    </div>
  );
};

export default ConvictionHistorySection;