import React from 'react';
import ActivitySection from './ActivitySection';
import ApplicantInfoSection from './ApplicantInfoSection';
import BackButtonHeader from '../../shared/BackButtonHeader';
import { useTranslation } from 'react-i18next';

const IdentificationForm = ({ setCurrentView }) => {
  const { t } = useTranslation();

  return (
    <>
      <BackButtonHeader setCurrentView={setCurrentView} />
      <div className="container py-4 p-md-5 my-md-4 bg-white card">
        <header className="row pt-md-2 pb-md-3">
          <div className="col-12 col-lg-3 mb-2 text-center text-lg-left">
            <img src="/f3078/logo.png" alt="Logo" style={{ height: "100px" }} />
          </div>
          <div className="col-12 col-lg-6 text-center">
            <h5 className="mb-0 font-weight-bold">{t("my.form3078.title1")}</h5>
            <p className="lead font-weight-normal">
              19 U.S.C. 66, 1551, 1555, 1565, 1624, 1641; 19 CFR 112.42, 118, 122.182, 146.6
            </p>
          </div>
          <div className="col-12 col-lg-3 text-center text-lg-left">
            <p className="font-weight-bold small">
              Approval No. 1651-0008 <br />
              Expiration Date 11/30/2019 <br />
              Estimated Burden 17 Min
            </p>
          </div>
        </header>

        <form>
          <ActivitySection />
          <ApplicantInfoSection />
        </form>
      </div>
    </>
  );
};

export default IdentificationForm;